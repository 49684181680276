<template>
<div id = "iPrint">
  <h2>Сопроводительный паспорт перевозки отходов</h2>
  <el-row :gutter="10">
  Регистрационный номер (порядковый номер паспорта в журнале регистрации сопроводительных паспортов перевозки отходов производства)
  <el-input-number v-model="action.number"></el-input-number>
  </el-row>
  <br>
  <el-row :gutter="10">
    Производитель отхода
  <el-input v-model="action.egr_full_name" :disabled="true"></el-input>
  </el-row>
  <br>
  <el-row :gutter="10">
    Собственник перевозимых отходов производства:
    <el-radio-group v-model="action.ca1" class="ml-4">
      <el-radio 
      label="Контрагент"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
  </el-row>
  <el-row :gutter="10" v-if="action.ca1 == 'Контрагент'">
  <el-select
          style="width: 100%"
          clearable
          @change="selectContractor"
          v-model="action.contractor1"
          filterable
          placeholder="Собственник"
        >
          <el-option
            v-for="item in contractorList1.content"
            :key="item.contractorId"
            :label="item.nameManual"
            :value="item.contractorId"
          >
          </el-option>
        </el-select>
  </el-row>
  <el-row :gutter="10" v-if="action.ca1 == 'Ручной ввод'">
    <el-input v-model="action.contractor1r"></el-input>
  </el-row>
  <br>
  <el-row :gutter="10">
    Получатель перевозимых отходов производства:
    <el-radio-group v-model="action.ca2" class="ml-4">
      <el-radio 
      label="Контрагент"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
  </el-row>
  <el-row :gutter="10" v-if="action.ca2 == 'Контрагент'">
  <el-select
          style="width: 100%"
          clearable
          @change="selectContractor"
          v-model="action.contractor2"
          filterable
          placeholder="Получатель"
        >
          <el-option
            v-for="item in contractorList1.content"
            :key="item.contractorId"
            :label="item.nameManual"
            :value="item.contractorId"
          >
          </el-option>
        </el-select>
  </el-row>
  <el-row :gutter="10" v-if="action.ca2 == 'Ручной ввод'">
    <el-input v-model="action.contractor2r"></el-input>
  </el-row>
  <br>
  <el-row :gutter="10">
    Перевозчик отходов производства:
    <el-radio-group v-model="action.ca3" class="ml-4">
      <el-radio 
      label="Контрагент"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
  </el-row>
  <el-row :gutter="10" v-if="action.ca3 == 'Контрагент'">
  
  <el-select
          style="width: 100%"
          clearable
          @change="selectContractor"
          v-model="action.contractor3"
          filterable
          placeholder="Перевозчик"
        >
          <el-option
            v-for="item in contractorList1.content"
            :key="item.contractorId"
            :label="item.nameManual"
            :value="item.contractorId"
          >
          </el-option>
        </el-select>
  </el-row>
  <el-row :gutter="10" v-if="action.ca3 == 'Ручной ввод'">
    <el-input v-model="action.contractor3r"></el-input>
  </el-row>

  <br>
  <el-row :gutter="10">
  Дата перевозки отходов производства: {{action.data}}
  </el-row>
  <br>
  <el-row>
  Транспортное средство - марка и регистрационный знак:
  <el-input v-model="action.vehicle"></el-input>
  </el-row>
  <br>
  <el-row>
    Фамилия, собственное имя, отчество (если таковое имеется) водителя:
  <el-input v-model="action.full_name"></el-input>
  </el-row>
  <br>

  <el-row v-for="item in waste" v-bind:key="item.ss01DictionaryId">
    <el-row>
      {{item.wasteCode}}-{{item.wasteName}}
      <br>
      Тара (упаковка) - наименование:
      <el-input v-model="item.tara_name"></el-input>
    </el-row>
    <br>
    <el-row>
      Тара (упаковка) - общий вес, тонн:
      <el-input v-model="item.tara_vight"></el-input>
    </el-row>
    <br>
  </el-row>

  <br>
  <el-row>
    Вес транспорта (брутто), тонн:
  <el-input v-model="action.vehicle_vight"></el-input>
  </el-row>
 <br>
  <el-row>
    Номер аварийной карточки:
  <el-input v-model="action.number_card"></el-input>
  </el-row>
 <br>
  <el-row :gutter="10">
    Собственник отходов производства:
    <el-radio-group v-model="action.ow" class="ml-4">
      <el-radio 
      label="Ответственное лицо"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
  </el-row>
  <el-row :gutter="10" v-if="action.ow == 'Ответственное лицо'">
  
            <el-select
              clearable
              class="searchPole"
              v-model="action.owner"
              value-key="id"
              placeholder="Собственник отходов производства:"
            >
              <el-option
                v-for="item in listResponsiblePersonForSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
  </el-row>
  <el-row :gutter="10" v-if="action.ow == 'Ручной ввод'">
    <el-input v-model="action.ownerr"></el-input>
  </el-row>
 <br>
 
  <el-row :gutter="10">
    Перевозчик отходов произвдства:
    <el-radio-group v-model="action.tr" class="ml-4">
      <el-radio 
      label="Ответственное лицо"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
  </el-row>
  <el-row :gutter="10" v-if="action.tr == 'Ответственное лицо'">
  
            <el-select
              clearable
              class="searchPole"
              v-model="action.transfer"
              value-key="id"
              placeholder="Перевозчик отходов произвдства:"
            >
              <el-option
                v-for="item in listResponsiblePersonForSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
  </el-row>
  <el-row :gutter="10" v-if="action.tr == 'Ручной ввод'">
    <el-input v-model="action.transferr"></el-input>
  </el-row>
  <br>
  <el-row :span="4">
      <el-select
        v-model="action.fileType"
        placeholder="Формат отчета"
      >
        <el-option
          v-for="item in fileTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
  </el-row>
  <br>
  <el-row>
    <input type="checkbox" id="checkboxPas" v-model="action.chek_null_value">
    <label for="checkboxPas"><b>Выгрузить сопроводительный паспорт без количества отходов производства, перевозимых рейсом</b></label>
  </el-row>
  <br>

  <el-button type="primary" @click='addPasportToTable(psId)'>Добавить паспорт</el-button>
  <el-button type="primary" @click='Print' v-if="false">Печать</el-button>
  
    
</div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';

export default {
  props: ["action","waste","psId"],
  name: "disposalPasportForm",
  data() {
    return {
      fileTypes: [
          {
            value: ".pdf",
            label: ".pdf",
          },
          {
            value: ".xls",
            label: ".xls",
          },
        ],
      contractor: "",
      data: {},
      const_options: {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
    };
  },

  computed: {
    ...mapGetters({
      contractorList1: "GETLISTCONTRACTORS",
      listResponsiblePersonForSelect: "GETLISTRESPONSIBLEPERSONFORSEARCH",
      NumberPassport: "GETDATASTORED",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    addPasportToTable (psId) {
      console.log(this.action);
      if (this.action.ca1 != 'Ручной ввод')
      this.action.contractor1r = null;
      else
      this.action.contractor1 = null;
      if (this.action.ca2 != 'Ручной ввод')
      this.action.contractor2r = null;
      else
      this.action.contractor2 = null;
      if (this.action.ca3 != 'Ручной ввод')
      this.action.contractor3r = null;
      else
      this.action.contractor3 = null;
      if (this.action.ow != 'Ручной ввод')
      this.action.ownerr = null;
      else
      this.action.owner = null;
      if (this.action.tr != 'Ручной ввод')
      this.action.transferr = null;
      else
      this.action.transfer = null;
      this.$emit('addPasportToTable', this.action,this.psId, this.waste);
      this.psId=psId;
      if (this.action.modal != undefined)
      this.$store.dispatch("setShowModal", this.action.modal);
      else
      this.$store.dispatch("setShowModal", null);
    },
    Print () {
      Vue.use(VueHtmlToPaper, this.const_options);
      Vue.use(VueHtmlToPaper);
      this.$htmlToPaper('iPrint');
    },
    getListResponsiblePersonForSelect() {
      let units = [];
      this.$store.dispatch("getListResponsiblePersonForSelect", units);
    },

    selectContractor() {
      console.log(this);
      /*this.data.
      this.$store.dispatch("searchContractorsByName", this.contractor);*/
    },

    addContractor() {
      this.$router.push("contractors/add");
    },
  },

  async mounted() {
    //this.user_id= Cookies.get('userId');
    //this.action.contractor1r = 1;
    this.data.page =0;
    this.data.size = 350;
    await this.$store.dispatch("getAllContractors", this.data);
    
    this.getListResponsiblePersonForSelect(-1);
    console.log("pass");
    console.log(this);
  },
};
</script>

<style scoped></style>
